export const gitIosAppleModels = () => {
    let devicePixelRatio = window.devicePixelRatio
    let devicePixelRatioParseInt = parseInt(window.devicePixelRatio)
    let iPhone4_4s = devicePixelRatio && devicePixelRatioParseInt === 2 && window.screen.width === 320 && window.screen.height === 480
    let iPhone5_5s_5c = devicePixelRatio && devicePixelRatioParseInt === 2 && window.screen.width === 320 && window.screen.height === 568
    let iPhone6_6s_7_8 = devicePixelRatio && devicePixelRatioParseInt === 2 && window.screen.width === 375 && window.screen.height === 667
    let iPhone6P_6sP_7P_8P = devicePixelRatio && devicePixelRatioParseInt === 3 && window.screen.width === 414 && window.screen.height === 736
    if (iPhone4_4s || iPhone5_5s_5c || iPhone6_6s_7_8 || iPhone6P_6sP_7P_8P) {
        return true
    } else {
        return false
    }
}