<template>
  <div class="transactionReport">
    <NavigationBar :title="title" v-show="is_navbar === 'true'"></NavigationBar>
    <div class="transactionReportCenter" :style="{ top: this.offsetTop }" v-show="!defaultPage">
      <van-list v-model="loading" :finished="finished" finished-text="仅展示最近100条交易记录…" @load="onLoad">
        <div class="transactionReportItem" :key="item.id" v-for="item in goodList">
          <div class="transactionReportItemLeft">
            <!-- <img :src="item.goods_img" alt="" srcset="" class="headImg" /> -->
            <img v-lazy="item.goods_img" alt="" srcset="" class="headImg" />
            <img src="../../static/images/soldOut@2x.png" alt="" class="headImg dealmarkImg" />
          </div>
          <div class="transactionReportItemRight">
            <div class="title" v-html="item.goodsItemTitle"></div>
            <div class="serviceName">{{ item.game_name }}|{{ item.game_area_service }}</div>

            <!-- <div class="priceContainer">
              <div class="priceRight">
                {{
                  item.price === item.price_original
                    ? ""
                    : "¥" + item.price_original
                }}
                11
              </div>
            </div> -->
            <div class="contractLinkContinaer">
              <div class="price">
                <div class="countPrice">¥</div>{{ item.amount }}
              </div>
              <div class="contractLink" @click="goViewContract(item.esign_url)">
                <!-- 查看电子协议合同 -->
              </div>
              <!-- <div class="peopleNumber" v-show="item.hot !== 0">
                               {{ item.hot }}
              </div> -->
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="defaultPageContainer" v-show="defaultPage">
      <div class="imgContainer">
        <img src="../../static/images/defaultPage@2x.png" alt="" class="defaultPageImg" />
        <div class="defaultPageText">什么都没有哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecentOrder } from "@/api/security";
import { NavigationBar } from "@/components";
import { gitIosAppleModels } from "@/utils/gitIosAppleModels";
export default {
  name: "TransactionReport",
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      defaultPage: false,
      goodList: [],
      title: "成交快报",
      offsetTop: "0px",
      is_navbar: "true",
    };
  },
  components: {
    NavigationBar,
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      this.offsetTop = "1.226667rem";
    } else if (isiOS) {
      let iosSafeArea = this.$route.query.iosSafeArea;
      let ios_6_7_8 = gitIosAppleModels();
      if (ios_6_7_8) {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : "1.226667rem";
      } else {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : 90 / (document.documentElement.clientWidth / 10) + "rem";
      }
    }
    if (this.is_navbar === "false") {
      this.offsetTop = "0px";
    }
  },
  methods: {
    onLoad() {
      let config = {
        page: this.page,
        page_size: this.page_size,
        app_source: 'tgy'
      };
      this.requestGetRecentOrder(config);
    },
    async requestGetRecentOrder(config) {
      this.loading = true;
      try {
        const result = await getRecentOrder(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let goodList = [...this.goodList, ...result.data];
          let newobj = {};
          // 去重
          this.goodList = goodList.reduce((preVal, curVal) => {
            newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
            return preVal;
          }, []);
          // 没有数据了
          if (result.data.length < this.page_size) {
            this.finished = true;
          } else {
            this.page += 1;
            this.loading = false;
          }
          if (this.page === 1 && result.data.length === 0) {
            this.defaultPage = true;
          } else {
            this.defaultPage = false;
          }
          // 缺省页
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
    goViewContract(esign_url) {
      if (esign_url == null || esign_url === "") {
        this.$toast("查看合同失败");
        return;
      }
      window.location.href = esign_url;
    },
  },
};
</script>

<style lang="less" scoped>
.transactionReport {
  min-height: 100vh;

  .transactionReportCenter {
    padding: 12px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // position: absolute;
    background: #f9f9f9;

    .transactionReportItem {
      padding: 8px;
      // width: 355px;
      height: 136px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      margin-bottom: 10px;
      box-sizing: border-box;

      .transactionReportItemLeft {
        width: 120px;
        height: 120px;
        position: relative;

        .headImg {
          width: 100%;
          height: 100%;
          border-radius: 4px 0px 0px 4px;
        }

        .dealmarkImg {
          width: 96px;
          height: 27px;
          position: absolute;
          top: 8px;
          left: -8px;
        }
      }

      .transactionReportItemRight {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        // justify-content: space-around;
        .title {
          display: -webkit-box;
          width: 210px;
          // height: 40px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #222222;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .serviceName {
          width: fit-content;
          box-sizing: border-box;
          margin-top: 6px;
          padding: 1px 5px;
          // height: 16px;
          background: #E3F3FF;
          border-radius: 4px;
          border: 1px solid rgba(80, 138, 255, 0.7);
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 10px;
          color: #508AFF;
          line-height: 14px;
          text-align: center;
          font-style: normal;
        }

        .priceContainer {
          display: flex;
          align-items: center;
          margin-top: 4px;

          .priceRight {
            height: 14px;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #aaaaaa;
            line-height: 14px;
            text-decoration: line-through;
          }
        }

        .contractLinkContinaer {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;

          .price {
            display: flex;
            // width: 78px;
            height: 30px;
            font-family: Avenir, Avenir;
            font-weight: 900;
            font-size: 22px;
            color: #FF0000;
            line-height: 30px;
            text-align: center;
            font-style: normal;

            .countPrice {
              margin-top: 9px;
              font-family: Avenir, Avenir;
              font-weight: 900;
              font-size: 12px;
              color: #FF0000;
              line-height: 16px;
              text-align: left;
              font-style: normal;
            }
          }

          .peopleNumber {
            height: 25px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #aaaaaa;
            line-height: 25px;
          }
        }
      }
    }
  }

  .defaultPageContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgContainer {
      width: 161px;
      height: 160px;

      .defaultPageImg {
        width: 100%;
        height: 100%;
      }

      .defaultPageText {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: black;
}

::v-deep p {
  margin: 0px;
}
</style>

